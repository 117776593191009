import { setLocale } from 'yup';

setLocale({
  mixed: {
    default: 'validation.invalid',
    required: 'validation.required',
  },
  string: {
    required: 'validation.required',
    email: 'validation.email',
    min: ({ min }) => ({ key: 'validation.min', values: { min } }),
    max: ({ max }) => ({ key: 'validation.max', values: { max } }),
  },
  number: {
    required: 'validation.required',
    min: ({ min }) => ({ key: 'validation.min-number', values: { min } }),
    max: ({ max }) => ({ key: 'validation.max-number', values: { max } }),
  }
})
