// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-hood-js": () => import("./../src/pages/hood.js" /* webpackChunkName: "component---src-pages-hood-js" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-map-tsx": () => import("./../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-pages-participate-tsx": () => import("./../src/pages/participate.tsx" /* webpackChunkName: "component---src-pages-participate-tsx" */),
  "component---src-pages-register-tsx": () => import("./../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */),
  "component---src-pages-secret-tsx": () => import("./../src/pages/secret.tsx" /* webpackChunkName: "component---src-pages-secret-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */)
}

