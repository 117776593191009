import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'

import EN from './locales/en-US.json'
import LT from './locales/lt-LT.json'
import UA from './locales/uk-UA.json'

const OPTIONS = {
  interpolation: {
    escapeValue: false,
  },
  detection: {
    lookupLocalStorage: 'lang',
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
  },
  lng: 'lt-LT',
  resources: {
    'en-US': { translation: EN },
    'lt-LT': { translation: LT },
    'uk-UA': { translation: UA },
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init(OPTIONS)

export default i18n;
