import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../mui-theme';

/**
 * This is what @material-ui/core/CssBaseline produces.
 * Adding it directly since for some reason @global styling
 * in jss doesn't add styles during SSR and they are loaded client side.
 * This causes a flicker. In theory they should work, but maybe our setup
 * has some issues.
 */
const globalCSS = `
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  strong, b {
    font-weight: bolder;
  }
  body {
    margin: 0;
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.default};
    font-size: ${theme.typography.body2.fontSize};
    font-family: ${theme.typography.body2.fontFamily};
    font-weight: ${theme.typography.body2.fontWeight};
    letter-spacing: ${theme.typography.body2.letterSpacing};
    line-height: ${theme.typography.body2.lineHeight};
  }
  @media print {
    body {
      background-color: #fff;
    }
  }
  body::backdrop {
    background-color: ${theme.palette.background.default};
  }
`
export const MuiThemeProvider = ({ children }) => {
  
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Philosopher:700&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
          integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
          crossOrigin=""
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <style dangerouslySetInnerHTML = {{__html: globalCSS}} />
        {children}
      </ThemeProvider>
    </>
  );
}
