import { ROLES } from './ROLES';
import { UserDetails } from 'types';
import { isBrowser } from 'utilities/gatsby'

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('auth')
    ? JSON.parse(window.localStorage.getItem('auth') as string)
    : {}

const setUser = (user: UserDetails | object) =>
  window.localStorage.setItem('auth', JSON.stringify(user))

export const handleLogin = ({ email, token, role, cityId, cityName }: UserDetails) => {
  return setUser({
    email,
    token,
    role,
    cityId,
    cityName
  })
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.token
}

export const isParticipant = () => {
  const user = getUser();
  return user.role === ROLES.AUTHENTICATED;
}

export const isAdmin = () => {
  const user = getUser();
  return user.role === ROLES.ADMIN;
}

export const isCityModerator = () => {
  const user = getUser();
  return user.role === ROLES.MODERATOR;
}

export const getUserToken = () => {
  const user = getUser();
  return user && user.token;
}

type Function = () => void;
type Callback = Function | undefined;

export const logout = (callback: Callback) => {
  setUser({})
  callback && callback()
}
