import React, { createContext, useState, ReactNode } from 'react';

import { UserDetails } from 'types'

import { handleLogin, logout, getUser} from '../services/auth/auth';

interface UserContext {
    user?: UserDetails;
    setUser: (user: UserDetails) => void;
    resetUser: () => void;
}

export const UserContext = createContext<UserContext>({
    user: undefined,
    setUser: () => {},
    resetUser: () => {}
});

interface Props {
  children: ReactNode;
}

export const UserContextProvider = (props: Props) => {
    const [state, setState] = useState<{user?: UserDetails}>({user: getUser()});
    return (
        <UserContext.Provider value={{
            user: state.user,
            setUser: (user: {
                email: string;
                token: string;
                role: string,
                cityId?: string,
                cityName?: string;
              }) => {
                // @ts-ignore
              handleLogin(user);
              setState({ user});
            },
            resetUser: () => {
              setState({user: undefined});
              logout();
            }
          }}>
            {props.children}
        </UserContext.Provider>
    )
}
