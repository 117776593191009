module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatvės muzikos diena","short_name":"GMD","start_url":"/","background_color":"#ffeb34","theme_color":"#ffeb34","display":"minimal-ui","icon":"src/images/gmd.jpeg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"69d9b5703844aec4f268fbcc2e428114"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
