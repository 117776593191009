/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it

import React from 'react';
import { I18nextProvider } from 'react-i18next';
// import { StrapiTranslationsProvider } from './src/components/providers/strapi-translations-provider';
import { MuiThemeProvider } from './src/components/providers/mui-theme-provider';
import i18n from './src/i18n'
import { UserContextProvider } from './src/components/global-context';
import './src/i18n/setYupMessages'
import './src/css/global.css'

export const wrapRootElement = ({ element }) => {
  return (
    <UserContextProvider>
      <I18nextProvider i18n={i18n}>
        <MuiThemeProvider>
          {element}
        </MuiThemeProvider>
      </I18nextProvider>
    </UserContextProvider>
  )
};

// TODO: uncomment when BE translations are needed
// export const wrapPageElement = ({ element }) => {
//   return (
//     <StrapiTranslationsProvider>
//       {element}
//     </StrapiTranslationsProvider>
//   );
// };
